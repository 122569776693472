import { useCallback, useMemo } from 'react';
import { useUserInfo } from './useUserInfo';
const EMPTY_GATES = new Set();
export const useGetHasGate = () => {
  const userInfo = useUserInfo();
  const userInfoGates = useMemo(() => {
    if (!userInfo) {
      return EMPTY_GATES;
    }
    const {
      gates
    } = userInfo;
    if (!gates) {
      return EMPTY_GATES;
    }
    return new Set(gates);
  }, [userInfo]);
  const hasGate = useCallback(gate => {
    return userInfoGates.has(gate);
  }, [userInfoGates]);
  return {
    hasGate,
    loading: !userInfo
  };
};