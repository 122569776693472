let hasSetSpeculationRulesScript = false;
export function useSetSpeculationRulesScript() {
  if (!HTMLScriptElement.supports || !HTMLScriptElement.supports('speculationrules') || hasSetSpeculationRulesScript) {
    return;
  }
  const scriptEl = document.createElement('script');
  scriptEl.type = 'speculationrules';
  const specRules = {
    prerender: [{
      where: {
        selector_matches: "[data-speculation-target='crm-links-CRM_OBJECT_RECORD']"
      },
      eagerness: 'moderate'
    }]
  };
  scriptEl.textContent = JSON.stringify(specRules);
  document.body.append(scriptEl);
  hasSetSpeculationRulesScript = true;
}