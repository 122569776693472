import { APPROVAL_SECTION_KEY } from './approval/types';
import { PROPERTIES_SECTION_KEY } from './properties/types';
import { ACTIVITY_SECTION_KEY } from './activity/types';
import { ASSOCIATIONS_SECTION_KEY } from './associations/types';
import { PRIORITY_SECTION_KEY } from './priority/types';
import { QUICK_ACTIONS_SECTION_KEY } from './actions/container/types';
// card_content: such as properties, displayed in the card content
// card_wrapper: such as styled_container or card_tooltip, not displayed in the content
// card_action: such as preview
// card_internal: such as quick_actions, not displayed in the content or used in registries
export const CARD_CONTENT = 'card_content';
export const CARD_WRAPPER = 'card_wrapper';
export const CARD_ACTION = 'card_action';
export const CARD_INTERNAL = 'card_internal';

// Create an array of content keys excluding the test section key

export const assertAllKeysAreIncluded = true;
export const CARD_CONTENT_KEYS = [PROPERTIES_SECTION_KEY, APPROVAL_SECTION_KEY, ACTIVITY_SECTION_KEY, ASSOCIATIONS_SECTION_KEY, PRIORITY_SECTION_KEY];

// Create an array of content keys excluding the test section key

export const assertAllInternalKeysAreIncluded = true;
export const CARD_INTERNAL_KEYS = [QUICK_ACTIONS_SECTION_KEY];