import Raven from 'raven-js';
export const INVALID_SECTION_SETTING = null;
export const getFormattedSectionSettings = sectionConfigs => {
  // @ts-expect-error TODO remove when all section types are added on FE
  return sectionConfigs.map(setting => {
    // return null if setting is not a valid JSON
    let sectionSettings = INVALID_SECTION_SETTING;
    try {
      // In case of empty settings, BE returns '{}'
      sectionSettings = JSON.parse(setting.sectionConfigJson || 'null');
    } catch (e) {
      Raven.captureMessage('Error parsing card section settings', {
        extra: {
          setting: setting.sectionConfigJson
        }
      });
    }
    return {
      sectionKey: setting.sectionType,
      settings: sectionSettings
    };
  });
};
export const getJSONFormattedSectionSettings = settings => {
  return settings.map((setting, index) => {
    if (setting.settings === undefined) {
      return undefined;
    }
    return {
      displayOrder: index,
      sectionType: setting.sectionKey,
      sectionConfigJson: JSON.stringify(setting.settings)
    };
  }).filter(setting => !!setting);
};