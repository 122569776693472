import produce from 'immer';
import { OBJECT_DELETED } from '../LocalMutationsStore';
/**
 * Clears synced object mutations from the store for a specific object type, preserving delete
 * mutations.
 *
 * Does not affect query-level mutations.
 *
 * Delete mutations are preserved because they can affect query results even after a refresh.
 * Due to search indexing lag, a deleted object may temporarily still appear in search results
 * from the server, so we need to maintain the delete state client-side indefinitely since we
 * can't confirm server-side deletion status reliably.
 *
 * All other mutations can be safely discarded since after a refresh we can rely on server data.
 *
 * @param currentState - The current state of the LocalMutationsStore
 * @param objectTypeId - The object type ID to clear mutations for
 * @returns A new state with specified object mutations removed, except for deletes
 */
export const clearSyncedObjectMutations = ({
  currentState,
  objectTypeId
}) => {
  return produce(currentState, draft => {
    const mutationsByObjectType = draft.individualObjects[objectTypeId];
    if (!mutationsByObjectType) return;

    // Filter out objects that don't have any delete mutations
    Object.entries(mutationsByObjectType).forEach(([objectId, objectMutations]) => {
      const hasDeleteMutation = objectMutations.includes(OBJECT_DELETED);
      if (!hasDeleteMutation) {
        delete mutationsByObjectType[objectId];
      }
    });
  });
};