import isPortalSpecificObjectType from 'customer-data-objects/crmObject/isPortalSpecificObjectType';
async function loadCardSectionRegistryMap(objectTypeId) {
  try {
    const {
      REGISTRY
    } = await import( /* webpackChunkName: "[request]" */`./${objectTypeId}/cardSectionRegistry`);
    return {
      registryMap: REGISTRY
    };
  } catch (e) {
    const isCustomObject = isPortalSpecificObjectType(objectTypeId);
    if (isCustomObject) {
      const {
        REGISTRY
      } = await import( /* webpackChunkName: "customObject" */`./customObject/cardSectionRegistry`);
      return {
        registryMap: REGISTRY
      };
    }
    const {
      REGISTRY
    } = await import( /* webpackChunkName: "common" */`./common/cardSectionRegistry`);
    return {
      registryMap: REGISTRY
    };
  }
}
export async function getRegistryForObjectType(objectTypeId) {
  const {
    registryMap
  } = await loadCardSectionRegistryMap(objectTypeId);
  return {
    registryMap
  };
}