import { useCallback } from 'react';
import { useLocalMutationsStore } from './LocalMutationsProvider';

/**
 * Hook to clear synced object mutations from the LocalMutationsStore for a specific object type,
 * preserving delete mutations. Does not affect query-level mutations.
 *
 * @example
 * ```tsx
 * const clearSyncedObjectMutations = useClearSyncedObjectMutations();
 *
 * // Clear synced contact mutations while preserving any contact potentially unsynced (async) operations
 * clearSyncedObjectMutations(CONTACT_TYPE_ID);
 * ```
 *
 * @returns A function that clears synced object mutations for a specific object type
 */
export const useClearSyncedObjectMutations = () => {
  const store = useLocalMutationsStore();
  const clearSyncedObjectMutations = useCallback(objectTypeId => {
    store.clearSyncedObjectMutations(objectTypeId);
  }, [store]);
  return clearSyncedObjectMutations;
};