import { Loadable } from 'foundations-components/transitional/utils/Loadable';
import { INVALID_SECTION_SETTING } from '../api/utils';
import { SkeletonText } from '../components/common/SkeletonText';
function isAsyncCardSectionRegistryEntry(registryEntry) {
  return !!registryEntry.componentLoader;
}
export function buildCardSectionRegistry(registryMap) {
  function getSectionKeys() {
    return registryMap ? Object.keys(registryMap) : [];
  }
  function getSectionType(sectionKey) {
    if (!registryMap) {
      return undefined;
    }
    const registryEntry = registryMap[sectionKey];
    if (!registryEntry) {
      return undefined;
    }
    return registryEntry.card.sectionType;
  }
  function getSectionComponent(sectionKey) {
    if (!registryMap) {
      return undefined;
    }
    const registryEntry = registryMap[sectionKey];
    if (!registryEntry) {
      return undefined;
    }
    if (isAsyncCardSectionRegistryEntry(registryEntry.card)) {
      return Loadable({
        loader: registryEntry.card.componentLoader,
        LoadingComponent: SkeletonText
      });
    }
    return registryEntry.card.Component;
  }
  function getSectionPreviewComponent(sectionKey) {
    if (!registryMap) {
      return undefined;
    }
    const registryEntry = registryMap[sectionKey];
    if (!registryEntry) {
      return undefined;
    }
    return registryEntry.card.PreviewComponent;
  }
  function getLazyLoadedTranslations(sectionKey) {
    if (!registryMap) {
      return undefined;
    }
    const registryEntry = registryMap[sectionKey];
    if (!registryEntry) {
      return undefined;
    }
    return registryEntry.card.lazyLoadedTranslations;
  }
  function getRequiredProperties(sectionKey, settings) {
    if (!registryMap) {
      return [];
    }
    const registryEntry = registryMap[sectionKey];
    if (!registryEntry) {
      return [];
    }
    return registryEntry.card.requiredProperties && settings !== INVALID_SECTION_SETTING ? registryEntry.card.requiredProperties(settings) : [];
  }
  function getSectionSettingComponent(sectionKey) {
    if (!registryMap) {
      return undefined;
    }
    const registryEntry = registryMap[sectionKey];
    if (!registryEntry || !registryEntry.settings) {
      return undefined;
    }
    return registryEntry.settings.Component;
  }

  // This is used to get config for all sections in SelectOptions
  function getAllSectionSettingConfigs() {
    if (!registryMap) {
      return undefined;
    }
    const configMap = new Map();
    Object.keys(registryMap).forEach(sectionKey => {
      var _registryEntry$settin;
      const registryEntry = registryMap[sectionKey];
      configMap.set(sectionKey, (registryEntry === null || registryEntry === void 0 || (_registryEntry$settin = registryEntry.settings) === null || _registryEntry$settin === void 0 ? void 0 : _registryEntry$settin.config) || undefined);
    });
    return configMap;
  }
  return {
    getSectionComponent,
    getSectionPreviewComponent,
    getLazyLoadedTranslations,
    getSectionKeys,
    getSectionType,
    getRequiredProperties,
    getSectionSettingComponent,
    getAllSectionSettingConfigs
  };
}