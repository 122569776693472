import { useEffect, useMemo, useRef } from 'react';
import { useFetchUserInfo } from '../useFetchUserInfo';
import { Metrics } from '../../Metrics';
import { useLegacyFastCrmObjectsSearch } from './useLegacyFastCrmObjectsSearch';
import { useNewFastCrmObjectsSearch } from './useNewFastCrmObjectsSearch';
import { gate } from 'hub-http/gates';
export function useFastCrmObjectsSearch({
  crmSearchQueryObject,
  pollInterval,
  skip,
  initialFetchPolicy = 'cache-first'
}) {
  const {
    data: userInfoData,
    loading: userInfoLoading,
    error: userInfoError
  } = useFetchUserInfo();
  useEffect(() => {
    if (userInfoLoading) {
      Metrics.counter('user-info-loading').increment();
      //if you are seeing this metric, it means that crm-object-table is not loading as fast as it could be.
      //please fire off a hub-http/userInfo request early in page load
    }
  }, [userInfoLoading]);
  const isUngatedForNewVersion = Boolean(userInfoData === null || userInfoData === void 0 ? void 0 : userInfoData.portal.enabled_gates.includes(gate('CRM:Grid:CrmSearchViaDFC')));
  const skipAnyRequest = Boolean(skip || userInfoLoading);
  const skipOldRequest = skipAnyRequest || isUngatedForNewVersion;

  //|| userInfoError will fallback to the legacy version if the user info call fails as a sane fallback
  const skipNewRequest = Boolean(skipAnyRequest || !isUngatedForNewVersion || userInfoError);
  const oldResult = useLegacyFastCrmObjectsSearch({
    crmSearchQueryObject,
    pollInterval,
    skip: skipOldRequest,
    initialFetchPolicy
  });
  const newResult = useNewFastCrmObjectsSearch({
    crmSearchQueryObject,
    pollInterval,
    skip: skipNewRequest,
    initialFetchPolicy
  });
  const resultToUse = isUngatedForNewVersion ? newResult : oldResult;
  const returnValue = useMemo(() => Object.assign({}, resultToUse, {
    loading: resultToUse.loading || userInfoLoading,
    error: resultToUse.error
  }), [resultToUse, userInfoLoading]);
  const startTimeRef = useRef(null);
  const nthCallRef = useRef(0);
  useEffect(() => {
    //if we're loading something and we havent tracked the start time, track it
    if (resultToUse.loading && startTimeRef.current === null) {
      startTimeRef.current = performance.now();
      return;
    }

    //if we're done loading and we have data, lets log the time and then reset the start time in case we re-query again (like due to polling)
    if (!resultToUse.loading && resultToUse.data && startTimeRef.current !== null) {
      const endTime = performance.now();
      const timeTaken = endTime - startTimeRef.current;
      nthCallRef.current++;
      Metrics.timer('CrmSearchViaDFC', {
        isUngatedForNewVersion: String(isUngatedForNewVersion),
        nthCall: nthCallRef.current.toString()
      }).update(timeTaken);
      startTimeRef.current = null; // Reset startTime for the next load
    }
  }, [isUngatedForNewVersion, resultToUse.data, resultToUse.loading]);
  return returnValue;
}