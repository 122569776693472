import { useMemo } from 'react';
import { useGetHasGate } from '../../hooks/useGetHasGate';
import { gate } from 'hub-http/gates';
import { useBoardPanelPresentationContext } from '../context/BoardPanelPresentationContext';
const GATE = gate('CRM:Pipelines:CRMObjectBoardCard');
export const useHasCardsV2 = () => {
  const {
    hasGate
  } = useGetHasGate();
  const {
    forceCardsTab
  } = useBoardPanelPresentationContext();
  return useMemo(() => {
    return hasGate(GATE) && !forceCardsTab;
  }, [hasGate, forceCardsTab]);
};