import { useCardPreloadStore } from '../providers/CardPreloadProvider';
import { useHasCardV2 } from '../sections/hooks/useHasCardV2';
export const useBoardCardSectionsLoading = () => {
  const hasCardV2 = useHasCardV2();
  const registryLoading = useCardPreloadStore(store => store.registryLoading);
  const cardSectionPropertiesLoading = useCardPreloadStore(store => store.cardSectionPropertiesLoading);
  const cardSectionSettingsLoading = useCardPreloadStore(store => store.cardSectionSettingsLoading);
  if (!hasCardV2) {
    return false;
  }
  return registryLoading || cardSectionPropertiesLoading || cardSectionSettingsLoading;
};