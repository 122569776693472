import { useMemo } from 'react';
import { useRecordCardProperties } from 'crm-object-board-core/properties';
import { useObjectTypeDefinition } from 'crm-object-board-core/objectTypeDefinition';
import { useTagPropertyName } from 'crm-object-board-core/tags';
import { useHasCardV2 } from '../card/hooks/useHasCardV2';
import { useCardSectionProperties } from 'crm-object-board-card/hooks';
export const getPropertiesForsearch = (typeDef, incoming) => {
  const unique = new Set(['hs_object_id', typeDef === null || typeDef === void 0 ? void 0 : typeDef.pipelinePropertyName, typeDef === null || typeDef === void 0 ? void 0 : typeDef.pipelineStagePropertyName, typeDef === null || typeDef === void 0 ? void 0 : typeDef.primaryDisplayLabelPropertyName, ...incoming].filter(Boolean) //remove null/undefined/empty
  );
  return Array.from(unique);
};
export const usePropertiesForSearch = () => {
  const hasCardV2 = useHasCardV2();
  const typeDef = useObjectTypeDefinition();
  const tagPropertyName = useTagPropertyName();
  // this can be removed when card v2 is fully rolled out
  const recordCardProperties = useRecordCardProperties();
  // this is provided by CardPreloadProvider (all properties required by sections)
  const cardSectionProperties = useCardSectionProperties();
  return useMemo(() => {
    if (!typeDef) {
      return null;
    }
    return getPropertiesForsearch(typeDef, [...(!hasCardV2 ? recordCardProperties : []), tagPropertyName || null, ...(hasCardV2 ? cardSectionProperties : [])]);
  }, [typeDef, tagPropertyName, recordCardProperties, cardSectionProperties, hasCardV2]);
};