/**
 * Abstract interface for client-side storage operations
 */

/**
 * LocalStorage implementation of ClientStorage
 */
export class LocalStorageClient {
  async getItem(key) {
    try {
      const item = localStorage.getItem(key);
      return await Promise.resolve(item);
    } catch (e) {
      throw new Error(`Failed to get item with key: ${key}`);
    }
  }
  async setItem(key, value) {
    try {
      localStorage.setItem(key, value);
      return await Promise.resolve();
    } catch (e) {
      throw new Error(`Failed to set item with key: ${key}`);
    }
  }
  async removeItem(key) {
    try {
      localStorage.removeItem(key);
      return await Promise.resolve();
    } catch (e) {
      throw new Error(`Failed to remove item with key: ${key}`);
    }
  }
}

// Default instance for use throughout the app
export const clientStorage = new LocalStorageClient();