/**
 * This hash is used to take in a CrmObjectsSearchQueryObject and generate a unique hash used for LocalMutationsStore
 *
 * It ignores parts of the query such as sort, count+offset, properties as these do not affect which objects match
 * the given query when bulk editing/deleting/mutating all objects in a query
 */
export const generateLocalMutationsQueryHash = ({
  queryObject
}) => {
  const {
    filterGroups,
    objectTypeId,
    query
  } = queryObject;
  /**
   * Query may be nullish, so we need to use an empty string as a fallback
   * in order to normalize the hash. We consider string | null | undefined
   * to be the same thing for the purposes of this hash.
   * We came to this decision after noticing that local mutations by query
   * were not working as expected when there was a mismatch between some
   * consumers representing an empty query as undefined and others as an
   * empty string.
   * Types are correct here, but if consumers pass in any other value we lose
   * that safety and it results in a runtime bug, this is defensive.
   */
  const parsedQuery = query !== null && query !== void 0 ? query : '';
  return `LocalMutationsCrmSearchQueryHash:${objectTypeId}-${parsedQuery}-${JSON.stringify(filterGroups)}`;
};