import { BaseCrmSearchError } from './BaseCrmSearchError';
const TIMEOUT_STATUS = 408;
export class CrmSearchCachingQueryTimeoutError extends BaseCrmSearchError {
  constructor(error) {
    super(error);
    this.name = 'CrmSearchCachingQueryTimeoutError';
  }
  static tryFromError(error) {
    if (error.status === TIMEOUT_STATUS) {
      return new CrmSearchCachingQueryTimeoutError(error);
    }
    return null;
  }
}