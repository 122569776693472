import { useMemo } from 'react';
import { getFormattedSectionSettings } from '../api/utils';
import { CARD_CONTENT_KEYS, CARD_WRAPPER } from '../sections/constants';
// This is called form CardPreloadProvider, with preloaded section settings.
export const useLoadCardSectionProperties = ({
  cardSectionSettings,
  cardSectionSettingsLoading,
  registry,
  registryLoading
}) => {
  const loading = cardSectionSettingsLoading || registryLoading;
  const contentRequiredProperties = useMemo(() => {
    if (loading) {
      return [];
    }
    const formattedSectionSettings = getFormattedSectionSettings(cardSectionSettings);
    return formattedSectionSettings.filter(s => CARD_CONTENT_KEYS.some(x => x === s.sectionKey)).map(({
      sectionKey,
      settings
    }) => {
      return registry.getRequiredProperties(sectionKey, settings);
    });
  }, [loading, cardSectionSettings, registry]);
  const wrapperRequiredProperties = useMemo(() => {
    if (loading) {
      return [];
    }
    return registry.getSectionKeys().filter(sectionKey => registry.getSectionType(sectionKey) === CARD_WRAPPER).map(sectionKey => registry.getRequiredProperties(sectionKey, undefined));
  }, [loading, registry]);
  const properties = useMemo(() => {
    if (loading) {
      return [];
    }
    return contentRequiredProperties.concat(wrapperRequiredProperties).flatMap(p => p);
  }, [loading, contentRequiredProperties, wrapperRequiredProperties]);
  return {
    loading,
    data: properties
  };
};