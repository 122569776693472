import { useApolloClient } from '@apollo/client';
import { useCallback, useEffect, useRef } from 'react';
import { useOnCrmMessageTopic } from 'crm-message-bus/useOnCrmMessageTopic';
import { TOPIC_NAMES } from 'crm-message-bus/types/MessageTopics';
import { useDataFetchingClient } from 'data-fetching-client';
import { REST_CRM_OBJECTS_SEARCH_QUERY } from './useFastCrmObjectsSearch/useNewFastCrmObjectsSearch';
import { CRM_OBJECT_TABLE_SEARCH_QUERY } from './types';
const DEBOUNCE_INTERVAL = 250;
const {
  UPDATE_OBJECTS,
  UPDATE_ASSOCIATIONS
} = TOPIC_NAMES;

/**
 * Configuration object for message handling.
 * We ignore local messages to avoid refetching the
 * table query in cases where we are already using
 * local mutations to update the data.
 */
const ON_MESSAGE_CONFIG = {
  ignoreLocal: true
};

/**
 * This hook sets up a listener for CRM message bus topics that
 * should trigger a refetch of the CRM object table search query.
 * Currently we refetch on UPDATE_OBJECTS and UPDATE_ASSOCIATIONS
 * since we know that refetching should get us the latest data.
 *
 * We might want to add some additional handling where we check
 * whether the updated objects are in the current search results
 * before refetching to minimize unnecessary refetching.
 *
 * Since search result sets are subject to indexing lag, we don't
 * currently refetch on object creation/deletion events. We might
 * want to consider adding some support for object creation/deletion
 * events in another way in the future, e.g. by prepending newly
 * created objects to search results (if they're of the same type)
 * and removing deleted objects from search results eagerly.
 */
export function useSetupRefetchSubscriber(skip) {
  const apolloClient = useApolloClient();
  const dataFetchingClient = useDataFetchingClient();
  const timeoutRef = useRef(undefined);
  const cleanupPendingTimeout = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = undefined;
    }
  }, []);
  const refetchTableQuery = useCallback(() => {
    if (skip) return;
    //there are two queries here, one "old" and one "new" related to CRM:Grid:CrmSearchViaDFC
    //when the gate is fully rolled out, we can remove one of these
    //
    //Because only one will be queried on either side of the gate, it is safe to refetch both, one will result in a no-op
    void dataFetchingClient.refetchQueries({
      include: [REST_CRM_OBJECTS_SEARCH_QUERY]
    });
    void apolloClient.refetchQueries({
      include: [CRM_OBJECT_TABLE_SEARCH_QUERY]
    });
    timeoutRef.current = undefined;
  }, [apolloClient, dataFetchingClient, skip]);
  const debouncedRefetchTableQuery = useCallback(() => {
    cleanupPendingTimeout();
    timeoutRef.current = setTimeout(refetchTableQuery, DEBOUNCE_INTERVAL);
  }, [cleanupPendingTimeout, refetchTableQuery]);

  /**
   * Cleanup on unmount and when apollo client or skip prop changes.
   */
  useEffect(() => cleanupPendingTimeout, [apolloClient, cleanupPendingTimeout, skip]);
  useOnCrmMessageTopic(UPDATE_OBJECTS, debouncedRefetchTableQuery, ON_MESSAGE_CONFIG);
  useOnCrmMessageTopic(UPDATE_ASSOCIATIONS, debouncedRefetchTableQuery, ON_MESSAGE_CONFIG);
  return refetchTableQuery;
}