import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { useBoardProps } from 'crm-object-board-core/boardProps';
import { useEffect, useState } from 'react';
import { getRegistryForObjectType } from './getRegistryForObjectType';
import { APPROVAL_SECTION_KEY } from '../sections/approval/types';
import { useHasPipelineApprovalAccess } from 'crm-object-board-core/frameworkAppSettings';

// This hook calls to getRegistryForObjectType to get the registry and configs async for the object type.
export const useCardSectionRegistryMap = ({
  skip
}) => {
  const {
    objectTypeId
  } = useBoardProps();
  const hasApprovalAccess = useHasPipelineApprovalAccess();
  const [registryMap, setRegistryMap] = useState({
    loading: true,
    data: undefined,
    error: undefined
  });
  useEffect(() => {
    if (skip) {
      setRegistryMap({
        loading: false,
        data: undefined,
        error: undefined
      });
      return;
    }
    async function getRegistryMap() {
      const {
        registryMap: map
      } = await getRegistryForObjectType(objectTypeId);
      if (!hasApprovalAccess) {
        const rest = _objectWithoutPropertiesLoose(map, [APPROVAL_SECTION_KEY].map(_toPropertyKey));
        setRegistryMap({
          loading: false,
          data: rest,
          error: undefined
        });
        return;
      }
      setRegistryMap({
        loading: false,
        data: map,
        error: undefined
      });
    }
    getRegistryMap().catch(e => {
      setRegistryMap({
        loading: false,
        data: undefined,
        error: e
      });
    });
  }, [objectTypeId, skip, hasApprovalAccess]);
  return registryMap;
};