import { useMemo } from 'react';
import { registerQuery, useQuery } from 'data-fetching-client';
import { getFrameworkDataSchemasClient } from 'framework-data-schema-resolvers';
import { withCrmObjectBoardNamespace } from '../../utils';
import { useBoardProps } from '../../boardProps/useBoardProps';
import { useSyncError } from '../../error/useSyncError';
export const GET_PROPERTY_GROUPS_QUERY = withCrmObjectBoardNamespace('GET_PROPERTY_GROUPS');
const fetcher = ({
  objectTypeId,
  showHighlySensitiveProperties
}) => {
  return getFrameworkDataSchemasClient().then(client => client.properties.getGroups({
    frameworkTypeIdentifier: objectTypeId,
    query: {
      showHighlySensitiveProperties: Boolean(showHighlySensitiveProperties)
    }
  }));
};
const GET_PROPERTY_GROUPS = registerQuery({
  args: ['objectTypeId', 'showHighlySensitiveProperties'],
  fieldName: GET_PROPERTY_GROUPS_QUERY,
  fetcher
});
export const useLoadPropertyGroups = () => {
  const {
    objectTypeId
  } = useBoardProps();
  const {
    data,
    loading,
    error
  } = useQuery(GET_PROPERTY_GROUPS, {
    variables: {
      objectTypeId,
      showHighlySensitiveProperties: true
    },
    skip: !objectTypeId
  });
  useSyncError(error);
  const propertyGroups = useMemo(() => {
    if (!data || !data[GET_PROPERTY_GROUPS_QUERY] || !Array.isArray(data[GET_PROPERTY_GROUPS_QUERY])) {
      return [];
    }
    return data[GET_PROPERTY_GROUPS_QUERY];
  }, [data]);
  return useMemo(() => ({
    error,
    loading,
    data: propertyGroups
  }), [propertyGroups, error, loading]);
};