import { useMemo } from 'react';
import { createMaybeCrmSearchError } from '..';
import { makeApolloLikeResultType } from '../typeUtils';
import { parseCrmObject } from './parseCrmObject';
import { usePollWhenVisible } from './usePollWhenVisible';
import { useCacheCrmObjectsSearchQueryVariables } from './useCacheCrmObjectsSearchQueryVariables';
import { useFastCrmObjectsSearch } from './useFastCrmObjectsSearch';

/** Please do not use this before reaching out to the codeowners of this repo!
 * use this hook if you want very raw low level consumption of the crmObjectsSearch query within grapqhl
 *
 * This DOES contain business logic to
 * - remove / filter out deleted objects caused by CrmSearch indexing lag
 * - poll (configurabl via pollInterval), while also taking into account page visibility to prevent uneccessary poll refreshes
 *
 * This explicitly does NOT contain business logic such as
 * - "If you are fetching deal amount, you'll probably also want deal currency"
 * - "Pipeline permissions relies on FE enforcement. Lets enforce it on this query"
 * - "While rendering objects in a list/table/board, generally you want to not display the previous data with a loading spinner instead of showing nothing"
 *
 * If you would like that business logic (most consumers should) please use useSensibleCrmObjectsSearchQuery
 */
export const useCrmObjectsSearch = ({
  crmSearchQueryObject,
  searchQueryVariablesLocalCacheKey,
  pollInterval,
  skip,
  skipPollAttempt = false,
  initialFetchPolicy = 'cache-first'
}) => {
  const {
    loading,
    data: rawData,
    error: rawError,
    refetch,
    startPolling,
    stopPolling,
    networkStatus
  } = useFastCrmObjectsSearch({
    skip,
    pollInterval,
    crmSearchQueryObject,
    initialFetchPolicy
  });
  useCacheCrmObjectsSearchQueryVariables({
    cacheKey: searchQueryVariablesLocalCacheKey,
    searchQueryVariables: crmSearchQueryObject,
    skip
  });
  usePollWhenVisible({
    pollInterval,
    refetch,
    skipPollAttempt,
    startPolling,
    stopPolling
  });
  const data = useMemo(() => {
    if (!(rawData !== null && rawData !== void 0 && rawData.crmObjectsSearch)) {
      return undefined;
    }
    const baseData = rawData.crmObjectsSearch;
    const baseResults = baseData.results;
    const processedResults = baseResults.map(parseCrmObject).filter(crmObject => {
      var _crmObject$properties;
      // Search (due to indexing delay) can sometimes give us objects that were deleted.
      // In this case all the object's property values will be null, so we can check one
      // to determine if the object is deleted. `hs_object_id` should always be populated,
      // so it is the one we chose to check.
      // Once this issue is resolved, we can simply check if the object is null: https://git.hubteam.com/HubSpot/CrmMiddlewarePlatformTeam/issues/252

      return Boolean((_crmObject$properties = crmObject.properties) === null || _crmObject$properties === void 0 ? void 0 : _crmObject$properties.hs_object_id.value);
    });
    const originalTotal = baseData.total;
    const numberOfObjectsRemoved = baseResults.length - processedResults.length;
    const total = originalTotal - numberOfObjectsRemoved;
    return {
      offset: rawData.crmObjectsSearch.offset,
      results: processedResults,
      total
    };
  }, [rawData]);
  const error = createMaybeCrmSearchError({
    objectTypeId: crmSearchQueryObject.objectTypeId,
    rawError,
    rawData,
    crmSearchQueryObject,
    operationName: 'CrmObjectsSearchQuery'
  });
  return useMemo(() => makeApolloLikeResultType({
    loading,
    data,
    error,
    networkStatus
  }), [data, error, loading, networkStatus]);
};