import { BaseCrmSearchError } from './BaseCrmSearchError';
export class CrmSearchStatus0Error extends BaseCrmSearchError {
  constructor(error) {
    super(error);
    this.name = 'CrmSearchStatus0Error';
  }
  static tryFromError(error) {
    if (error.status !== 0 || !/failed with status 0/i.test(error.message)) {
      return null;
    }
    return new CrmSearchStatus0Error(error);
  }
}