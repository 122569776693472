import { DEAL_TO_PRIMARY_COMPANY_ASSOCIATION_ID, GENERAL_DEAL_TO_CONTACT_ASSOCIATION_ID, GENERAL_TICKET_TO_CONTACT_ASSOCIATION_ID, TICKET_TO_PRIMARY_COMPANY_ASSOCIATION_ID, TASK_TO_CONTACT_ASSOCIATION_ID, TASK_TO_COMPANY_ASSOCIATION_ID, LEAD_TO_CONTACT_ASSOCIATION_ID, LEAD_TO_COMPANY_ASSOCIATION_ID } from 'crm-index-associations-lib/constants/AssociationTypeIds';
import { COMPANY_TYPE_ID, CONTACT_TYPE_ID, DEAL_TYPE_ID, TICKET_TYPE_ID, TASK_TYPE_ID, LEAD_TYPE_ID } from 'customer-data-objects/constants/ObjectTypeIds';
import { useBoardProps } from '../boardProps/useBoardProps';
const LocalBoardMetadataPerObjectType = {
  [DEAL_TYPE_ID]: {
    cardAssociations: {
      value: {
        primaryAssociationTypeId: GENERAL_DEAL_TO_CONTACT_ASSOCIATION_ID,
        secondaryAssociationTypeId: DEAL_TO_PRIMARY_COMPANY_ASSOCIATION_ID
      }
    },
    cardInactivity: {
      value: {
        property: 'notes_last_updated'
      }
    },
    boardSettingsMultiCurrency: {
      value: true
    }
  },
  [CONTACT_TYPE_ID]: {
    cardInactivity: {
      value: {
        property: 'notes_last_updated'
      }
    }
  },
  [COMPANY_TYPE_ID]: {
    cardInactivity: {
      value: {
        property: 'notes_last_updated'
      }
    }
  },
  [TICKET_TYPE_ID]: {
    cardAssociations: {
      value: {
        primaryAssociationTypeId: GENERAL_TICKET_TO_CONTACT_ASSOCIATION_ID,
        secondaryAssociationTypeId: TICKET_TO_PRIMARY_COMPANY_ASSOCIATION_ID
      }
    },
    cardInactivity: {
      value: {
        property: 'hs_lastactivitydate'
      }
    },
    cardTooltip: {
      value: {
        property: 'content'
      }
    }
  },
  [TASK_TYPE_ID]: {
    cardAssociations: {
      value: {
        primaryAssociationTypeId: TASK_TO_CONTACT_ASSOCIATION_ID,
        secondaryAssociationTypeId: TASK_TO_COMPANY_ASSOCIATION_ID
      }
    }
  },
  [LEAD_TYPE_ID]: {
    cardAssociations: {
      value: {
        primaryAssociationTypeId: LEAD_TO_CONTACT_ASSOCIATION_ID,
        secondaryAssociationTypeId: LEAD_TO_COMPANY_ASSOCIATION_ID
      }
    }
  }
};

// TODO remove when card library is fully rolled out.
// This will be provided in card library in cardSectionRegistry files
export const useLocalBoardMetadata = () => {
  const {
    objectTypeId
  } = useBoardProps();
  return LocalBoardMetadataPerObjectType[objectTypeId];
};