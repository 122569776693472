import { useQuery } from 'data-fetching-client';
import { GET_USER_BOARD_CARD_CONFIG_QUERY } from '../../__generated__/chirp/com/hubspot/crm/pipeline/board/configuration/rpc/CrmPipelineBoardConfigurationService.dfc';

// Fetch settings from user customizations stored in DB
export const useLoadBoardCardSectionSettings = ({
  pipelineId,
  objectTypeId,
  skip
}) => {
  var _data$getUserBoardCar;
  const {
    loading,
    error,
    data
  } = useQuery(GET_USER_BOARD_CARD_CONFIG_QUERY, {
    skip,
    variables: {
      pipelineId,
      objectTypeId
    }
  });
  return {
    loading,
    error,
    data: data === null || data === void 0 || (_data$getUserBoardCar = data['getUserBoardCardConfig']) === null || _data$getUserBoardCar === void 0 ? void 0 : _data$getUserBoardCar['boardCardSectionConfigurations']
  };
};