import { getPerformanceAPI } from './internal/fromWindow';
import getMarkerName from './internal/getMarkerName';
import performanceMarkInvariant from './marks/performanceMarkInvariant';
import { Metrics } from './internal/Metrics';
const mark = (marker, metricsAdditionalDimensions = {}, skipMetrics = false) => {
  performanceMarkInvariant(marker);
  const perfAPI = getPerformanceAPI();
  if (!perfAPI) {
    return false;
  }
  const name = getMarkerName(marker);
  if (name) {
    if (!skipMetrics) {
      Metrics.timer(name, metricsAdditionalDimensions).update(perfAPI.now());
    }
    perfAPI.mark(name);
  }
  return true;
};
export default mark;